import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import styled from "styled-components";

import PageBlock from "../components/Layout/PageBlock";
import PageLayout from "../components/Layout/PageLayout";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { PageBlockTitle, CTALink, PageBlockNextPageDivider, primaryColour } from "../styles/component-stylings";
import { ArrowRight } from "react-feather";

const QuoteContainer = styled.blockquote`
    all: unset;
    display: block;
    background: white;
    padding: 1.25rem 2rem 1.25rem 2rem;
    text-align: center;
    margin: 3rem auto;
    width: 50%;
    max-width: 500px;
    border-radius: 1rem;
    border: solid 2px ${primaryColour};
    box-shadow: 1rem 1rem 0 0 ${primaryColour};
    font-weight: bold;
    font-style: italic;
`;

const IconBlockContainer = styled.div`
    width: 100%;
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`;

const IconContainer = styled.div`
    height: 200px;
    flex: 1 1 250px; // flex: 1 0 calc(100% / 3);
    padding: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 100ms ease-in;

    & > p {
        text-align: center;
        margin-bottom: 0;
    }

    &:hover {
        filter: brightness(0.9);
        box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    }
`;

const IconImgContainer = {
    height: "120px",
    width: "120px",
};

const IconText = styled.p`
    font-weight: bold;
`;

const PAGE_TITLE = "Who we are";

const WhoIsDSCPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Helmet>
                    <meta
                        name='description'
                        content='A more detailed look at who we are at Digital Surveillance Collection from the Australian Federal Police'
                    />
                </Helmet>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>

                <QuoteContainer>"Delivering lawful covert access to communications and electronic data."</QuoteContainer>

                <p>
                    Digital Surveillance Collection (DSC) is a branch in the Australian Federal Police that focuses on supporting covert technical electronic
                    surveillance and intelligence operations. We are responsible for providing technical assistance and solutions in support of AFP digital
                    surveillance activities.
                </p>
                <p>We operate within the following areas:</p>
                <IconBlockContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/icon_hacker.svg' alt='Icon of a person on a laptop' />
                        <IconText>
                            Covert digital
                            <br />
                            surveillance operations
                        </IconText>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/icon_bug.svg' alt='Icon of a software bug' />
                        <IconText>
                            Covert surveillance
                            <br />
                            software development
                        </IconText>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/icon_systems.svg' alt='Icon of a cloud and server rack' />
                        <IconText>
                            Obfuscated and
                            <br />
                            misattributed online access
                        </IconText>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage
                            style={IconImgContainer}
                            objectFit='contain'
                            src='../images/icon_analytics.svg'
                            alt='Icon of a graphs and magnifying glass'
                        />
                        <IconText>
                            Enhanced data
                            <br />
                            analytics and enrichment
                        </IconText>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/icon_hardware.svg' alt='Icon of an IC with traces' />
                        <IconText>
                            Custom covert electronic
                            <br />
                            surveillance devices
                        </IconText>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage
                            style={IconImgContainer}
                            objectFit='contain'
                            src='../images/icon_encryption.svg'
                            alt='Icon of a phone with a padlock on the screen'
                        />
                        <IconText>
                            Encrypted communications
                            <br />
                            exploitation
                        </IconText>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage
                            style={IconImgContainer}
                            objectFit='contain'
                            src='../images/icon_security.svg'
                            alt='Icon of a shield with nodes coming out, with a padlock in it'
                        />
                        <IconText>
                            Data integrity
                            <br />
                            and secure networking
                        </IconText>
                    </IconContainer>
                    <IconContainer>
                        <StaticImage style={IconImgContainer} objectFit='contain' src='../images/icon_antenna.svg' alt='Icon of a cellular antenna' />
                        <IconText>
                            Telecommunications and
                            <br />
                            data interception
                        </IconText>
                    </IconContainer>
                </IconBlockContainer>

                <PageBlockTitle>Our values</PageBlockTitle>
                <p>We follow and stand by core values to consistently produce success.</p>
                <CTALink to='/who-we-are/our-values' rel='next'>
                    <span>Read more</span>
                    <ArrowRight />
                </CTALink>

                <PageBlockTitle>Partners</PageBlockTitle>
                <p>We work alongside many different organisations to keep Australia safe.</p>
                <CTALink to='/who-we-are/partners' rel='next'>
                    <span>Learn more</span>
                    <ArrowRight />
                </CTALink>
                <PageBlockNextPageDivider>
                    <CTALink $invert $borderColour={primaryColour} to='/the-work' rel='next'>
                        <span>The work</span>
                        <ArrowRight />
                    </CTALink>
                </PageBlockNextPageDivider>
            </PageBlock>
        </PageLayout>
    );
};

export default WhoIsDSCPage;
